:root {
  --vinous: #1919df;
  --beige: #FDFAF8;
  --light-gray: #CECECE;
  --dark-gray: #7F7F7F;
  --black: #000000;
}

body {
  background-color: var(--beige);
  margin: 0;
  font-family: Arial, sans-serif;
}

.auth-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 2rem;
  box-sizing: border-box;
}

.auth-form {
  background-color: var(--beige);
  border-radius: 8px;
  width: 100%;
  max-width: 400px;
  padding: 4rem;
  margin-bottom: 1.5rem; 
}
.auth-footer {
  color: var(--dark-gray);
  font-size: 0.8rem;
  text-align: center;
  margin-top: auto; 
}

.input-container {
  position: relative;
  margin-bottom: 1rem;
  width: 100%;
  max-width: 375px;
}

.input-container input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid var(--light-gray);
  border-radius: 4px;
  font-size: 0.9rem;
}

input {
  margin-bottom: 1rem;
  padding: 0.75rem;
  border: 1px solid var(--light-gray);
  border-radius: 4px;
  font-size: 0.9rem;
}

.dropdown-container{
  position: relative;
  margin-bottom: 1rem;
  width: 100%;
  max-width: 400px;
}

.dropdown-container select {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid var(--light-gray);
  border-radius: 4px;
  font-size: 0.9rem;
}

select {
  margin-bottom: 1rem;
  padding: 0.75rem;
  border: 1px solid var(--light-gray);
  border-radius: 4px;
  font-size: 0.9rem;
  background-color: white;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1 1L5 5L9 1' stroke='%237F7F7F' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/></svg>");
  background-repeat: no-repeat;
  background-position-x: calc(100% - 10px);
  background-position-y: center;
  padding-right: 30px;
}

select::-ms-expand {
  display: none;
}


.input-icon {
  position: absolute;
  right: -0.5rem;
  top: 35%;
  transform: translateY(-40%);
  display: flex;
  color: var(--dark-gray);
  align-items: center;
  justify-content: center;
}

.logo {
  display: block;
  margin: -130px auto 4.5em;
  width: 300px;
}
.wenzi-gender {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.wenzi-gender button {
  width: 48%;
  padding: 0.75rem;
  border: 1px solid var(--dark-gray);
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
  color: var(--dark-gray);
}

.wenzi-gender button:focus,
.wenzi-gender button.active {
  border-color: var(--vinous);
  color: var(--vinous);
  background-color: white;
}

.form-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.back-btn {
  background-color: white;
  color: var(--black);
  border: 1px solid var(--black);
  padding: 0.75rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  width: 48%;
}
.back-btn:hover,
.back-btn:focus,
.back-btn:active {
  color: var(--vinous);
  border-color: var(--vinous);
}

.submit-wenz-btn {
  background-color: var(--vinous);
  color: white;
  border: 1px solid var(--vinous);
  padding: 0.75rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  width: 48%;
}

.copy-btn {
  position: absolute;
  right: -10px;
  top: 40%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.copy-btn svg {
  width: 15px;
  height: 15px;
  color: var(--dark-gray);
}

h1 {
  color: var(--black);
  text-align: center;
  margin-bottom: 2rem;
}

h2 {
  color: var(--dark-gray);
  text-align: center;
  margin-bottom: 0.5rem;
}

p {
  color: var(--dark-gray);
  text-align: center;
  margin-bottom: 2rem;
  font-size: 0.9rem;
}

form {
  display: flex;
  flex-direction: column;
}

.form-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  font-size: 0.8rem;
  width: 100%;
}

.submit-btn {
  background-color: var(--vinous);
  color: white;
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  width: 100%;
}

.submit-btn:hover {
  opacity: 0.9;
}

.switch-auth {
  margin-top: 1rem;
  font-size: 0.9rem;
}

a {
  color: var(--vinous);
  text-decoration: none;
  font-weight: bold;
}

a:hover {
  text-decoration: underline;
}

label {
  font-weight: bold;
}

.main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 2rem;
  box-sizing: border-box;
  text-align: center;
}

.main-logo {
  margin: -70px auto 4.5em;
  width: 300px;
}

.main-description {
  max-width: 600px;
  margin-bottom: 2rem;
  font-size: 1.1rem;
  line-height: 1.5;
}

.main-buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.main-btn {
  padding: 0.75rem 2rem;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: bold;
  text-decoration: none;
  transition: opacity 0.3s ease;
}

.login-btn {
  background-color: white;
  color: var(--black);
  border: 2px solid var(--black);
}

.login-btn:hover {
  color: var(--vinous);
  border-color: var(--vinous);
}

.signup-btn {
  background-color: var(--vinous);
  color: white;
  border: 2px solid var(--vinous);
}

.signup-btn:hover {
  opacity: 0.9;
}

.location-container {
  display: flex;
  margin-bottom: 1rem; 
}

.half-width {
  width: calc(50% - 0.5rem); 
}

.half-width select {
  width: 100%;
}

.input-container {
  position: relative;
  margin-bottom: 10px; /* Reduce the bottom margin to decrease space */
}

.consent-text {
  font-size: 0.8rem;
  color: #555;
  line-height: 1.2;
  margin-top: 1px;    /* Reduce the top margin */
  margin-bottom: 10px; /* Adjust as needed */
}

.terms-footer {
  margin-top: 2rem;
  text-align: right;  /* Changed from center to right */
}

textarea {
  width: 100%;
  min-height: 80px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  resize: vertical;
  font-family: inherit;
  margin-bottom: 15px;
}

textarea:focus {
  outline: none;
  border-color: #007bff;
}

/* Add specific styles for the modal textarea */
.modal-textarea {
  width: 94%;  /* This matches your previous textarea2 width */
  min-height: 80px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  resize: vertical;
  font-family: inherit;
  margin-bottom: 15px;
}

.modal-textarea:focus {
  outline: none;
  border-color: #007bff;
}

.phone-input-container {
  width: 100% !important;
  max-width: 400px !important;
  margin-bottom: 1rem;
}

.phone-input {
  width: 100% !important;
  max-width: 400px;
  height: 42px !important;
  padding: 0.75rem !important;
  padding-left: 48px !important;
  border: 1px solid var(--light-gray) !important;
  border-radius: 4px !important;
  font-size: 0.9rem !important;
}

/* Style the flag dropdown button */
.react-tel-input .flag-dropdown {
  border: 1px solid var(--light-gray) !important;
  border-radius: 4px 0 0 4px !important;
  background-color: white !important;
}

/* Style the selected flag */
.react-tel-input .selected-flag {
  background-color: white !important;
  border-radius: 4px 0 0 4px !important;
  height: 40px !important;
}

.password-toggle {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  position: absolute;
  right: -0.5rem;
  top: 35%;
  transform: translateY(-40%);
  color: var(--dark-gray);
  display: flex;
  align-items: center;
  justify-content: center;
}

.password-toggle .input-icon {
  width: auto;
  height: auto;
}

.password-toggle:hover {
  color: #000;
}

.error-message {
  color: #dc3545;
  font-size: 0.875rem;
  margin-top: 0.5rem;
  text-align: left;
  padding: 0.5rem;
}

.waitlist-btn {
  background-color: var(--vinous);
  color: white;
  border: 2px solid var(--vinous);
  padding: 0.75rem 2rem;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: opacity 0.3s ease;
  width: 300px;  /* Making it wider */
}

.waitlist-btn:hover {
  opacity: 0.9;
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 1.5rem;
}

.modal-buttons button {
  flex: 1;
  padding: 0.75rem 2rem;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.modal-buttons button[type="button"] {  /* Cancel button */
  background-color: white;
  color: var(--black);
  border: 2px solid var(--black);
}

.modal-buttons button[type="button"]:hover {
  color: var(--vinous);
  border-color: var(--vinous);
}

.modal-buttons button[type="submit"] {  /* Submit button */
  background-color: var(--vinous);
  color: white;
  border: 2px solid var(--vinous);
}

.modal-buttons button[type="submit"]:hover {
  opacity: 0.9;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background-color: var(--beige);
  border-radius: 8px;
  width: 90%;
  max-width: 350px;
  padding: 1.5rem;
  position: relative;
}

.modal h2 {
  color: var(--dark-gray);
  text-align: center;
  margin-bottom: 1rem;
  font-size: 1.1rem;
}

.modal form {
  display: flex;
  flex-direction: column;
}

.success-message {
  color: green;
  text-align: center;
  margin-top: 1rem;
}

.error-message {
  color: red;
  text-align: center;
  margin-top: 1rem;
}
